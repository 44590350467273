import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import React from 'react';
import { imgOteLogoWhiteSvg, imgOteTrophySvg } from '~/src/images';
import { OteGame } from '~/src/models/OteGame';
import { OteSeries, isSeriesWinner } from '~/src/models/OteSeries';
import { OteStage } from '~/src/models/OteStage';
import { OteTeam } from '~/src/models/OteTeam';
import { OteTeamStanding } from '~/src/models/OteTeamStandings';
import { colorFromInt } from '~/src/overtime-lib/src/www/lib';
import './PlayoffsBracket.scss';

const _TeamLogo = ({ ote_team }: { ote_team: OteTeam }) => {
	return ote_team?.logo_path ? (
		<img alt={ote_team.name} src={`https://images.overtime.tv/${ote_team.logo_path}?width=96&format=webp`} />
	) : ote_team ? (
		<div className="LogoPlaceholder" style={{ backgroundColor: colorFromInt(ote_team.primary_color) }} />
	) : (
		<div className="LogoBackground">
			<img alt={'TBD'} src={imgOteLogoWhiteSvg} />
		</div>
	);
};

const _BracketLines = ({ direction, lines }: { direction: 'down' | 'up'; lines: 1 | 2 }) => {
	return (
		<div className={`BracketLines${direction == 'up' ? ' Up' : ''}`}>
			<div className={`Branch${lines == 1 ? ' Single' : ''}`}></div>
			<div className="Stem"></div>
		</div>
	);
};

const _BracketCard = ({
	ote_series,
	ote_stage,
	ote_team_standings,
}: {
	ote_series: OteSeries;
	ote_stage: OteStage;
	ote_team_standings: OteTeamStanding[];
}) => {
	const isFinals = ote_series.name == 'Finals';
	const ote_series_ote_team_a = ote_series.ote_series_ote_teams[0];
	const ote_series_ote_team_b = ote_series.ote_series_ote_teams[1];
	const isWinnerTeamA = isSeriesWinner(ote_series_ote_team_a, ote_series);
	const isWinnerTeamB = isSeriesWinner(ote_series_ote_team_b, ote_series);
	const isSeriesComplete = isWinnerTeamA || isWinnerTeamB;
	const isSeriesCurrent = !isSeriesComplete && ote_series_ote_team_a && ote_series_ote_team_b;

	const ote_team_standing_a = ote_team_standings.find(
		(t) => t.ote_seasons_ote_team.ote_team_id == ote_series_ote_team_a?.ote_team_id,
	);
	const ote_team_standing_b = ote_team_standings.find(
		(t) => t.ote_seasons_ote_team.ote_team_id == ote_series_ote_team_b?.ote_team_id,
	);

	return (
		<div
			className={`BracketCard${isFinals ? ' Finals' : ''}${isSeriesComplete ? ' Complete' : ''}${isSeriesCurrent ? ' Current' : ''}`}
		>
			<div className={`Team${isWinnerTeamB ? ' Loser' : ''}`}>
				<_TeamLogo ote_team={ote_team_standing_a?.ote_seasons_ote_team.ote_team} />
				<div className="TeamStanding">
					{ote_team_standing_a ? <div className="Position">{ote_team_standing_a?.position}</div> : null}
					<h3 className="TeamName">{ote_team_standing_a?.ote_seasons_ote_team.ote_team.team_code ?? 'TBD'}</h3>
				</div>
			</div>
			<div className="Series">
				{isFinals ? (
					<div className="Trophy">
						<img src={imgOteTrophySvg} alt="OTE Trophy" />
					</div>
				) : (
					<>
						<div className="Stage">{ote_stage.name}</div>
						<div className="BestOf">Best of {ote_series.max_fixtures}</div>
					</>
				)}
				<div className="SeriesScore">
					{ote_series_ote_team_a?.series_wins ?? 0}-{ote_series_ote_team_b?.series_wins ?? 0}
				</div>
			</div>
			<div className={`Team${isWinnerTeamA ? ' Loser' : ''}`}>
				<_TeamLogo ote_team={ote_team_standing_b?.ote_seasons_ote_team.ote_team} />
				<div className="TeamStanding">
					{ote_team_standing_b ? <div className="Position">{ote_team_standing_b?.position}</div> : null}
					<h3 className="TeamName">{ote_team_standing_b?.ote_seasons_ote_team.ote_team.team_code ?? 'TBD'}</h3>
				</div>
			</div>
		</div>
	);
};

const _PlayoffsBracket = ({
	ote_games,
	ote_team_standings,
}: {
	ote_games: OteGame[];
	ote_team_standings: OteTeamStanding[];
}) => {
	// const ote_games_fetch = useFetchAndSubscribe<OteGame[]>({
	// 	url: `${Urls.ApiRestOteGamesUrl}?all=true`,
	// 	key: 'ote_games',
	// });
	// const ote_games = ote_games_fetch?.result.read();

	// const ote_team_standings_fetch = useFetchAndSubscribe<OteTeamStanding[]>({
	// 	url: `${Urls.ApiRestOteTeamStandingsCurrentUrl}`,
	// 	key: 'ote_team_standings',
	// });
	// const ote_team_standings = ote_team_standings_fetch?.result.read();

	const ote_seasons = _.uniqBy(
		ote_games.map((g) => g.ote_season),
		'id',
	);

	const regularSeasonId = ote_seasons?.find((season) => season.name.includes('Season'))?.id;
	const playoffSeasonId = ote_seasons?.find((season) => season.name.includes('Playoffs'))?.id;

	const regularSeasonStandings = ote_team_standings.filter(
		(s) => s.ote_seasons_ote_team.ote_season_id == regularSeasonId,
	);

	const playoffGames = _.orderBy(
		ote_games.filter((g) => g.ote_season_id == playoffSeasonId),
		'starts_at',
		'asc',
	);

	const ote_series = _.uniqBy(
		playoffGames.map((g) => g.ote_series),
		'id',
	);
	const ote_stages = _.uniqBy(
		playoffGames.map((g) => g.ote_stage),
		'id',
	);

	// TODO: Pull the games/rounds dynamically instead of hardcoding
	// This is a quick turnaround solution meant just for OTE Season 3 playoffs
	const seriesRound2a = ote_series.find((s) => s.name == 'Round 2.1');
	const seriesRound2b = ote_series.find((s) => s.name == 'Round 2.2');
	const seriesSemisA = ote_series.find((s) => s.name == 'Semi-Finals.1');
	const seriesSemisB = ote_series.find((s) => s.name == 'Semi-Finals.2');
	const seriesFinals = ote_series.find((s) => s.name == 'Finals');

	return (
		<div className="BracketCards">
			<_BracketCard
				ote_series={seriesRound2a}
				ote_stage={ote_stages.find((s) => s.id == seriesRound2a.ote_stage_id)}
				ote_team_standings={regularSeasonStandings}
			/>
			<_BracketLines direction={'down'} lines={2} />
			<_BracketCard
				ote_series={seriesSemisA}
				ote_stage={ote_stages.find((s) => s.id == seriesSemisA.ote_stage_id)}
				ote_team_standings={regularSeasonStandings}
			/>
			<_BracketLines direction={'down'} lines={1} />
			<_BracketCard
				ote_series={seriesFinals}
				ote_stage={ote_stages.find((s) => s.id == seriesFinals.ote_stage_id)}
				ote_team_standings={regularSeasonStandings}
			/>
			<_BracketLines direction={'up'} lines={1} />
			<_BracketCard
				ote_series={seriesSemisB}
				ote_stage={ote_stages.find((s) => s.id == seriesSemisB.ote_stage_id)}
				ote_team_standings={regularSeasonStandings}
			/>
			<_BracketLines direction={'up'} lines={2} />
			<_BracketCard
				ote_series={seriesRound2b}
				ote_stage={ote_stages.find((s) => s.id == seriesRound2b.ote_stage_id)}
				ote_team_standings={regularSeasonStandings}
			/>
		</div>
	);
};

const PlayoffsBracket = ({
	ote_games,
	ote_team_standings,
}: {
	ote_games: OteGame[];
	ote_team_standings: OteTeamStanding[];
}) => {
	return (
		<div className="PlayoffsBracket">
			<div className="BracketHeader">
				<h1>Playoffs</h1>
				<a href={'/scores'}>
					Scores <FontAwesomeIcon icon={faArrowRight} />
				</a>
			</div>

			<_PlayoffsBracket ote_games={ote_games} ote_team_standings={ote_team_standings} />

			{/* <div className="Eliminations"></div> */}
		</div>
	);
};

export default PlayoffsBracket;
