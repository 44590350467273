import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import _ from 'lodash';
import React from 'react';
import Suspended from '~/src/components/Suspended';
import { Urls } from '~/src/lib/enums/urls';
import { OteTeamStanding } from '~/src/models/OteTeamStandings';
import useFetch from '~/src/overtime-lib/src/www/hooks/useFetch';
import { colorFromInt } from '~/src/overtime-lib/src/www/lib';
import { OteTeam } from '../../models/OteTeam';
import './Standings.scss';

const Team = ({
	ote_team,
	ote_team_standing,
	hideStandings,
}: {
	ote_team: OteTeam;
	ote_team_standing: OteTeamStanding;
	hideStandings: boolean;
}) => {
	let instagramHandle = null;
	try {
		instagramHandle = ote_team.instagram_url
			? new URL(ote_team.instagram_url)?.pathname.split('/')[1]?.toLowerCase()
			: null;
	} catch {
		// If the instagram URL is invalid, we cannot get the handle, so leave it as null
	}

	return (
		<div className="Team">
			{!hideStandings && <div className="Rank">{ote_team_standing.position ?? '-'}</div>}
			<a className="inherit" href={`/teams/${ote_team.slug}`}>
				{ote_team.logo_path ? (
					<img alt={ote_team.name} src={`https://images.overtime.tv/${ote_team.logo_path}?width=96&format=webp`} />
				) : (
					<div className="LogoPlaceholder" style={{ backgroundColor: colorFromInt(ote_team.primary_color) }} />
				)}
			</a>
			<div className="NameAndOrg">
				<div className="Name">
					<a className="inherit Team" href={`/teams/${ote_team.slug}`}>
						{ote_team.name}
					</a>
				</div>
				<div className="Instagram">
					{instagramHandle ? (
						<a className="inherit" href={ote_team.instagram_url}>
							@{instagramHandle}
						</a>
					) : null}
				</div>
			</div>
			{hideStandings ? (
				<a href={`/teams/${ote_team.slug}`} className="ViewLink">
					<div>View</div>
					<FontAwesomeIcon icon={faArrowRight} />
				</a>
			) : (
				<div className="Record">
					<div>{ote_team_standing.wins ?? 0}</div>
					<div>-</div>
					<div>{ote_team_standing.losses ?? 0}</div>
				</div>
			)}
		</div>
	);
};

const _Standings = ({ hideStandings = false }: { hideStandings: boolean }) => {
	const fetchStandings = useFetch<OteTeamStanding[]>({
		url: `${Urls.ApiRestOteTeamStandingsCurrentUrl}`,
		key: 'ote_team_standings',
	});
	const fetchTeams = useFetch<OteTeam[]>({
		url: `${Urls.ApiRestOteTeamsCurrentUrl}`,
		key: 'ote_teams',
	});
	const ote_team_standings = fetchStandings?.result.read();
	const ote_teams = fetchTeams?.result.read();

	const sortedTeams = hideStandings
		? _.orderBy(ote_teams, (t) => t.name, 'asc')
		: _.sortBy(ote_team_standings, [
				(s) => s.position,
				(s) => s.wins * -1,
				(s) => s.losses,
				(s) => s.ote_seasons_ote_team.ote_team.name,
			]);
	return (
		<>
			{sortedTeams.map((s, i) => (
				<Team
					key={s.id}
					ote_team={hideStandings ? s : s.ote_seasons_ote_team?.ote_team}
					ote_team_standing={s}
					hideStandings={hideStandings}
				/>
			))}
		</>
	);
};

const Standings = ({ hideStandings = false }: { hideStandings?: boolean }) => {
	return (
		<Suspended fallback={<></>}>
			<div className="IndexStandings">
				<h1>{hideStandings ? 'Teams' : 'Standings'}</h1>
				<_Standings hideStandings={hideStandings} />
			</div>
		</Suspended>
	);
};

export default Standings;
